import React, { useState } from 'react';

const FAQ = ({ faqs }) => {
  const [activeFaqId, setActiveFaqId] = useState(null);
  const [visibleFaqsCount, setVisibleFaqsCount] = useState(5); // Initial number of FAQs to show

  const toggleFaq = (id) => {
    setActiveFaqId(activeFaqId === id ? null : id);
  };

  const showMoreQuestions = () => {
    setVisibleFaqsCount((prevCount) => prevCount + 5); // Show 5 more questions
  };

  return (
    <div className="faq-accordion">
      {faqs.slice(0, visibleFaqsCount).map((faq) => (
        <div key={faq.id} className="faq-item">
          <button
            className={`faq-question sm:text-vw-3xl text-vw-4xl leading-min ${activeFaqId === faq.id ? 'active' : ''}`}
            onClick={() => toggleFaq(faq.id)}
            aria-expanded={activeFaqId === faq.id}
            aria-controls={`faq-answer-${faq.id}`}
          >
            {faq.question}
            <span className="faq-toggle text-primary border-primary">{activeFaqId === faq.id ? '-' : '+'}</span>
          </button>
          <div
            className={`faq-answer sm:text-vw-xl text-vw-3xl ${activeFaqId === faq.id ? 'active' : ''}`}
            id={`faq-answer-${faq.id}`}
          >
            <p>{faq.answer}</p>
          </div>
        </div>
      ))}
      {visibleFaqsCount < faqs.length && (
        <button className="show-more text-primary sm:text-vw-xl text-vw-3xl flex items-center cursor-pointer ml-auto" onClick={showMoreQuestions}>
          more questions <svg width="1em" height="1em" viewBox="0 0 330 330" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393  c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393  s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" fill="#0047FF"></path></svg>
        </button>
      )}
    </div>
  );
};

export default FAQ;
