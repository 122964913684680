import { useEffect, useMemo, useState } from 'react'
import Image from 'next/image'
import { useFormContext } from 'react-hook-form';

import { useShopContext } from '../../../contexts/ShopContext';
import { useOrderContext } from '../../../contexts/OrderContext';

import SuccessPopup from '../../../components/SuccessPopup';
import ShopSummaryBox from '../../../components/ShopSummaryBox';
import ProductCard from '../../../components/ProductCard';
import PolicyText from '../../../components/PolicyText';
import { TextInput, Checkbox, FormErrors } from '../../../components/Forms';

import shopBgImage from '../../../public/img/shop-bg-desktop.png';


const ProductsQuantityController = () => {
  const { products, interiorStyles, currentInteriorStyle, apartmentTypes, currentApartmentType } = useShopContext();
  const { setValue } = useFormContext();

  useEffect(() => {
    if (currentApartmentType && currentInteriorStyle) {
      setValue("products", Object.fromEntries(
        products
          .filter(prod =>
            prod.interiorStyle.slug === currentInteriorStyle
            && !!prod.apartmentTypes.find(type => type.slug === currentApartmentType)
          )
          .map(prod => ([prod.id, { quantity: prod.defaultQuantity }]))
      ))

      setValue('interiorStyle', interiorStyles.find(s => s.slug === currentInteriorStyle).id)
      setValue('apartmentType', apartmentTypes.find(p => p.slug === currentApartmentType).id)
    }
  }, [currentApartmentType, currentInteriorStyle])  // eslint-disable-line

  return null;
}

const sortProductsByCardSize = (products) => {
  // thanks to https://stackoverflow.com/a/48833378/10393714
  const order = ["big", "medium", "small"];
  return products
    .sort((a, b) => order.indexOf(a.cardSize) - order.indexOf(b.cardSize))
    .sort((a, b) => a.cardSize === b.cardSize ? a.sortOrder - b.sortOrder : 0);
}


export default function ShopSection({ siteSetting, className = '' }) {
  const {
    products,
    interiorStyles,
    currentInteriorStyle,
    setCurrentInteriorStyle,
    apartmentTypes,
    currentApartmentType,
    setCurrentApartmentType,
    apartmentRooms,
    getProductsCount
  } = useShopContext();

  const {
    hasSubmitted,
    errors,
    watch,
    totalPrice
  } = useOrderContext();

  const [currentRoom, setCurrentRoom] = useState('all');
  const [deliveryInfoShown, setDeliveryInfoShown] = useState(false);
  const [successPopupShown, setSuccessPopupShown] = useState(false);

  const filteredProducts = useMemo(
    () => {
      let prods = products?.filter(prod =>
        prod.interiorStyle.slug === currentInteriorStyle
        && !!prod.apartmentTypes.find(type => type.slug === currentApartmentType)
      )

      if (prods && currentRoom !== 'all') {
        prods = prods.filter(prod => !!prod.apartmentRooms.find(room => room.slug === currentRoom))
      }

      return sortProductsByCardSize(prods || [])
    },
    [products, currentApartmentType, currentInteriorStyle, currentRoom]
  )

  const handleStyle = (stl) => {
    setCurrentInteriorStyle(stl);
    document.getElementById('packs')?.scrollIntoView();
  }

  useEffect(() => {
    if (deliveryInfoShown) {
      setTimeout(() => {
        document.getElementById('delivery')?.scrollIntoView();
      }, 0)
    }
  }, [deliveryInfoShown])

  useEffect(() => {
    if (currentApartmentType && currentInteriorStyle) {
      setCurrentRoom('all');
      document.getElementById('prods')?.scrollIntoView();
    }
  }, [currentApartmentType, currentInteriorStyle])

  useEffect(() => {
    if (hasSubmitted) setSuccessPopupShown(true);
  }, [hasSubmitted])

  return (
    <>
      <section id="shop" className={`relative sm:scroll-m-40 scroll-m-24 ${className}`}>
        <div className="desktop-only absolute inset-x-0 top-0">
          <Image
            src={shopBgImage}
            alt="new form - shop - let's start by choosing your interior style"
            className="w-full mix-blend-hard-light"
          />
        </div>

        <div className="container-main">
          <h2 className="text-vw-8xl font-bold leading-min relative z-10">
            Let’s start, choose<br />your interior style
          </h2>

          <div
            id="styles"
            className="sm:grid flex flex-col sm:gap-vw-6 gap-vw-12 relative z-10 mt-vw-20 scroll-mt-20"
            style={{ gridTemplateColumns: `repeat(${interiorStyles.length}, minmax(0, 1fr))` }}
          >
            {interiorStyles.map((stl, idx) => (
              <button
                key={idx}
                type="button"
                className={`
                  w-full sm:h-vw-92 h-vw-124
                  bg-white sm:rounded-vw-3xl rounded-vw-5xl
                  sm:ring-16 ring-6 ${currentInteriorStyle === stl.slug ? 'ring-primary' : 'ring-transparent'}
                  relative transition-[outline]
                `}
                onClick={() => handleStyle(stl.slug)}
              >
                <div className="w-full h-full sm:rounded-vw-3xl rounded-vw-5xl relative overflow-hidden">
                  <Image src={stl.image.src} alt={stl.image.alt} fill={true} sizes="28vw" />
                </div>

                <div
                  className={`
                    ring-4 ring-white sm:rounded-vw-2xl rounded-vw-3xl
                    absolute left-1/2 bottom-0
                    -translate-x-1/2 translate-y-1/2
                    sm:px-vw-10 px-vw-20 sm:py-vw-3.5 py-vw-6 sm:mb-0 mb-vw-8
                  `}
                  style={{ backgroundColor: stl.color }}
                >
                  <p className="sm:text-vw-xl text-vw-4xl" style={{ color: stl.textColor }}>
                    {stl.name}
                  </p>
                </div>

                {idx === 0 && (
                  <div className="bg-primary rounded-vw-2xl absolute top-vw-4 right-vw-4 px-vw-4 py-vw-2 w-1/3 sm:w-1/2 border-8 border-white">
                    <p className="text-vw-4xl text-white font-bold uppercase text-left leading-none">best seller</p>
                  </div>
                )}
              </button>
            ))}
          </div>

          <div
            id="packs"
            className="relative z-10 transition-opacity sm:scroll-m-16 scroll-m-24 sm:mt-vw-36 mt-vw-48"
            style={{
              visibility: currentInteriorStyle ? 'visible' : 'hidden',
              height: currentInteriorStyle ? 'auto' : '0',
              overflow: currentInteriorStyle ? 'visible' : 'hidden',
            }}
          >
            <h3 className="sm:text-vw-6xl text-vw-7xl font-bold leading-none">
              How many <br mobile-only="" />rooms?
            </h3>

            <div className="flex sm:flex-row flex-col sm:gap-vw-2 gap-vw-4 sm:mt-vw-8 mt-vw-14">
              {apartmentTypes.map((type, idx) => (
                <button
                  key={idx}
                  type="button"
                  className={`
                    centered flex-col flex-grow sm:h-vw-30 h-vw-48
                    text-center
                    ${currentApartmentType === type.slug ? 'bg-primary text-white' : 'bg-white text-black'}
                    sm:rounded-vw-3xl rounded-vw-5xl transition-colors
                  `}
                  onClick={() => setCurrentApartmentType(type.slug)}
                >
                  <p className="sm:text-vw-3xl text-vw-5xl">
                    {type.name}
                  </p>
                  <p className="sm:text-vw-base text-vw-3xl mt-vw-1">
                    {getProductsCount(undefined, type.slug)} items
                  </p>
                </button>
              ))}
            </div>
          </div>

          <div>
            <div
              id="prods"
              className={`transition-opacity sm:scroll-m-16 scroll-m-24 ${(currentInteriorStyle && currentApartmentType) ? 'mt-vw-36' : ''}`}
              style={{
                visibility: (currentInteriorStyle && currentApartmentType) ? 'visible' : 'hidden',
                height: (currentInteriorStyle && currentApartmentType) ? 'auto' : 0,
                overflow: (currentInteriorStyle && currentApartmentType) ? 'visible' : 'hidden',
              }}
            >
              <h3 className="sm:text-vw-6xl text-vw-7xl font-bold leading-none">
                What’s in <br mobile-only="" />your pack
              </h3>

              <div className="desktop-only mt-vw-6">
                <div className="flex flex-wrap">
                  <button
                    type="button"
                    className={`
                      centered-y h-vw-12
                      ${currentRoom === 'all' ? 'bg-primary text-white' : 'bg-white text-black'}
                      transition-colors
                      rounded-vw-2xl px-vw-8
                    `}
                    onClick={() => setCurrentRoom('all')}
                  >
                    <p className="text-vw-base">
                      All <span className="font-bold">{getProductsCount(undefined, undefined)}</span>
                    </p>
                  </button>

                  {apartmentRooms.map((room, idx) => {
                    const prodsCount = getProductsCount(undefined, undefined, room.slug);

                    return prodsCount > 0 && (
                      <button
                        key={idx}
                        type="button"
                        className={`
                          centered-y h-vw-12
                          ${currentRoom === room.slug ? 'bg-primary text-white' : 'bg-white text-black'}
                          transition-colors
                          rounded-vw-2xl px-vw-8 m-vw-1
                        `}
                        onClick={() => setCurrentRoom(room.slug)}
                      >
                        <p className="text-vw-base">
                          {room.name} <span className="font-bold">{prodsCount}</span>
                        </p>
                      </button>
                    )
                  })}
                </div>
              </div>

              <div className="flex w-full items-stretch sm:mt-vw-4 mt-vw-12">
                <div className="flex-grow">
                  <div className="sm:grid hidden grid-cols-6 gap-vw-2 w-full">
                    {filteredProducts.map(prod => {
                      let quantity = watch(`products.${prod.id}.quantity`)
                      return (
                        <ProductCard
                          key={prod.id}
                          size={prod.cardSize}
                          data={prod}
                          quantity={quantity}
                          disabled={totalPrice <= 10_000 && quantity > 0}
                        />
                      )
                    })}
                  </div>

                  <div className="mobile-only grid grid-cols-1 gap-vw-6">
                    {filteredProducts.map(prod => {
                      let quantity = watch(`products.${prod.id}.quantity`)
                      return (
                        <ProductCard
                          key={prod.id}
                          size="mobile"
                          data={prod}
                          quantity={quantity}
                          disabled={totalPrice <= 10_000 && quantity > 0}
                        />
                      )
                    })}
                  </div>

                  <div
                    id="delivery"
                    className="sm:scroll-m-16 scroll-m-24 sm:mt-vw-16 mt-vw-36"
                    style={{
                      visibility: deliveryInfoShown ? 'visible' : 'hidden',
                      height: deliveryInfoShown ? 'auto' : 0,
                      overflow: deliveryInfoShown ? 'visible' : 'hidden',
                    }}
                  >
                    <h3 className="sm:text-vw-6xl text-vw-7xl font-bold">
                      Please provide your contact information for a detailed follow-up from us.
                    </h3>

                    <div className="sm:mt-vw-6 mt-vw-16">
                      <h5 className="sm:text-vw-base text-vw-4xl sm:mb-vw-3 mb-vw-6">
                        Contact information
                      </h5>

                      <div className="flex sm:flex-row flex-col sm:gap-vw-2 gap-vw-4 w-full">
                        <TextInput
                          name="firstName"
                          rules={{ required: true }}
                          placeholder="First name"
                          className="sm:w-1/2"
                        />
                        <TextInput
                          name="lastName"
                          rules={{ required: true }}
                          placeholder="Last name"
                          className="sm:w-1/2"
                        />
                      </div>

                      <div className="flex sm:flex-row flex-col sm:gap-vw-2 gap-vw-4 w-full sm:mt-vw-2 mt-vw-4">
                        <TextInput
                          name="email"
                          type="email"
                          rules={{ required: true }}
                          placeholder="Email"
                          className="sm:w-1/2"
                        />
                        <TextInput
                          name="phone"
                          type="tel"
                          rules={{ required: true }}
                          placeholder="Phone"
                          className="sm:w-1/2"
                        />
                      </div>

                      <div className="sm:mt-vw-6 mt-vw-16">
                        <h5 className="sm:text-vw-base text-vw-4xl sm:mb-vw-3 mb-vw-6">
                          Shipping address
                        </h5>

                        <div className="flex sm:flex-row flex-col sm:gap-vw-2 gap-vw-4">
                          {/* <SelectInput
                            options={[
                              { name: 'Dubai', value: 'dubai' },
                            ]}
                            name="emirate"
                            rules={{ required: true }}
                            placeholder="Emirate"
                            className="sm:w-1/2 z-10"
                          /> */}
                          <TextInput
                            name="emirate"
                            rules={{ required: true }}
                            placeholder="Emirate"
                            readonly={true}
                            className="sm:w-1/2"
                          />
                          <TextInput
                            name="city"
                            rules={{ required: true }}
                            placeholder="City"
                            className="sm:w-1/2"
                          />
                        </div>

                        <div className="flex sm:flex-row flex-col sm:gap-vw-2 gap-vw-4 sm:mt-vw-2 mt-vw-4">
                          <TextInput
                            name="address"
                            rules={{ required: true }}
                            placeholder="Address"
                            className="sm:flex-grow"
                          />
                          <TextInput
                            name="apartment"
                            rules={{ required: true }}
                            type="number"
                            placeholder="Apartment"
                            className="flex-shrink-0 sm:w-vw-60"
                          />
                        </div>
                      </div>

                      <div className="flex justify-start sm:mt-vw-8 mt-vw-20">
                        <Checkbox
                          name="policy"
                          rules={{ required: true }}
                          label={
                            <PolicyText url={siteSetting.privacyPolicyPage.slug} />
                          }
                        />
                      </div>

                      <FormErrors errors={errors} />

                      <button className="mobile-only big-ass-button w-full mt-vw-16">
                        Checkout
                      </button>
                    </div>
                  </div>
                </div>

                <div className="flex-shrink-0 sm:w-vw-76 w-0 sm:pl-vw-6">
                  <div className="sticky top-vw-4">
                    <ShopSummaryBox
                      variant="desktop-sidebar"
                      isNext={deliveryInfoShown}
                      onNext={() => setDeliveryInfoShown(true)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ShopSummaryBox
        variant="mobile-fixed"
        hidden={!currentInteriorStyle || !currentApartmentType}
        isNext={deliveryInfoShown}
        onNext={() => setDeliveryInfoShown(true)}
      />

      <ProductsQuantityController />

      {successPopupShown && (
        <SuccessPopup onClose={() => setSuccessPopupShown(false)} />
      )}
    </>
  )
}
