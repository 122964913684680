import { useState } from 'react';
import { CheckboxNoContext, SelectInputNoContext, FormErrors, InputPlaceholder } from '../../../components/Forms';
import PolicyText from '../../../components/PolicyText';
import SuccessPopup from '../../../components/SuccessPopup';

export default function InquiryForm({ siteSetting, className }) {
  const [inquiryFormData, setInquiryFormData] = useState({
    'inquiry-name': '',
    'inquiry-phone': '',
    'inquiry-budget': '',
    'inquiry-apartmentType': '',
    'inquiry-policy': true,
  });
  const [isSubmitting, setSubmitting] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [successPopupShown, setSuccessPopupShown] = useState(false);
  const selectOptions = [
    {
      name: 'Studio',
      value: 'Studio'
    },
    {
      name: '1 Bedroom',
      value: '1 Bedroom'
    },
    {
      name: '2 Bedroom',
      value: '2 Bedroom'
    },
    {
      name: '3 Bedroom',
      value: '3 Bedroom'
    },
    {
      name: 'Villa',
      value: 'Villa'
    },
    {
      name: 'Other',
      value: 'Undefined'
    },
  ];

  const handleInquiryChange = (e) => {
    const { name, value } = e.target;
    if (name === 'inquiry-phone') {
      // Allow only numbers, parentheses, dashes, spaces, plus sign, and ensure it starts with a +
      if (value && value[0] !== '+') {
        let newError = {};
        newError['inquiry-phone'] = 'Phone must start with +';
        setFormErrors(newError)
        return;
      }
      if (value.length > 1 && !/^[0-9() -]*$/.test(value.substring(1))) return;
    }

    setInquiryFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    
    // Clear the error for this field
    if (formErrors[name]) {
      setFormErrors(prevErrors => ({
        ...prevErrors,
        [name]: undefined
      }));
    }
  };

  const handleInquiryCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setInquiryFormData(prevState => ({
      ...prevState,
      [name]: checked
    }));
    
    // Clear the error for this field
    if (formErrors[name]) {
      setFormErrors(prevErrors => ({
        ...prevErrors,
        [name]: undefined
      }));
    }
  };

  // check Name and Phone fields
  const validateForm = () => {
    const newErrors = {};
    if (!inquiryFormData['inquiry-name']) {
      newErrors['inquiry-name'] = 'Name is required';
    }

    const phone = inquiryFormData['inquiry-phone'];
    if (!phone) {
      newErrors['inquiry-phone'] = 'Phone number is required';
    } else {
      // Remove non-digit characters for counting
      const digitOnlyPhone = phone.replace(/[^\d]/g, '');

      // Check for single plus and digit count
      if (phone[0] !== '+' || (phone.match(/\+/g) || []).length > 1) {
        newErrors['inquiry-phone'] = 'Phone must start with +';
      } else if (digitOnlyPhone.length < 10) {
        newErrors['inquiry-phone'] = 'Phone is too short';
      }
    }
    if (!inquiryFormData['inquiry-policy']) {
      newErrors['inquiry-policy'] = true;
    }
    setFormErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const submitInquiry = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return; // Stop the submission if the form is invalid
    }
    setSubmitting(true);

    // Add the current URL to the form data
    const formDataWithUrl = {
      ...inquiryFormData,
      'inquiry-url': typeof window !== 'undefined' ? window.location.href : '',
    };
    
    try {
      const response = await fetch('/wt/api/send-inquiry/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formDataWithUrl),
      });

      if (response.ok) {
        setSuccessPopupShown(true);
        if (typeof window !== "undefined" && typeof window.gtag === "function") window.gtag('event', 'сustom_offer_request', {
          'event_name': 'сustom_offer_request',
          'value': 1,
        });
        // Reset form if needed
        setInquiryFormData({
          'inquiry-name': '',
          'inquiry-phone': '',
          'inquiry-budget': '',
          'inquiry-apartmentType': '',
          'inquiry-policy': true,
        });
      } else {
        // Handle form submission errors
        // This could depend on your backend implementation
        const errorData = await response.json();
        setFormErrors(errorData.errors || {});
      }
    } catch (error) {
      // Handle network error
      setFormErrors({ network: 'An error occurred while sending the form. Please try again later.' });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <section className={`container-main inqiry-container flex flex-wrap sm:flex-nowrap mt-vw-4 gap-vw-8 ${className}`}>
      <div className="form-description sm:w-1/2">
        <h2 className="sm:text-vw-6xl text-vw-7xl font-bold leading-none mb-vw-8">What is your budget and apartment type?</h2>
        <p className="sm:text-vw-3xl text-vw-5xl mb-vw-4">We will calculate <br mobile-only=""/>a customized offer for&nbsp;you.</p>
      </div>
      <div className="sm:w-1/2">
        <div className="flex sm:flex-row flex-col sm:gap-vw-2 gap-vw-4 w-full">
          <label className="sm:w-1/2">
            <div className="relative">
              <input
                name='inquiry-name'
                type="text"
                value={inquiryFormData['inquiry-name']}
                onChange={handleInquiryChange}
                className={`form-text-input w-full ${inquiryFormData['inquiry-name'] ? 'sm:pt-vw-3.5 pt-vw-10' : ''}`}
                rules={{ required: true }}
              />
              <InputPlaceholder placeholder='Name' value={inquiryFormData['inquiry-name']} />
            </div>
            {formErrors['inquiry-name'] && (
              <div className="error-message bg-red-600 sm:rounded-b-vw-3xl rounded-b-vw-6xl relative z-[-1]">
                <div className="sm:py-vw-1 py-vw-2 sm:px-vw-8 px-vw-16">
                  <p className="sm:text-vw-base text-vw-3xl text-white">{formErrors['inquiry-name']}</p>
                </div>
                <div className="w-full sm:h-vw-8 h-vw-16 bg-red-600 absolute bottom-full"></div>
              </div>
            )}
          </label>
          <label className="sm:w-1/2">
            <div className="relative">
              <input
                name='inquiry-phone'
                type="tel"
                value={inquiryFormData['inquiry-phone']}
                onChange={handleInquiryChange}
                className={`form-text-input w-full ${inquiryFormData['inquiry-phone'] ? 'sm:pt-vw-3.5 pt-vw-10' : ''}`}
                rules={{ required: true }}
              />
              <InputPlaceholder placeholder="Phone" value={inquiryFormData['inquiry-phone']} />
            </div>
            {formErrors['inquiry-phone'] && (
              <div className="error-message bg-red-600 sm:rounded-b-vw-3xl rounded-b-vw-6xl relative z-[-1]">
                <div className="sm:py-vw-1 py-vw-2 sm:px-vw-8 px-vw-16">
                  <p className="sm:text-vw-base text-vw-3xl text-white">{formErrors['inquiry-phone']}</p>
                </div>
                <div className="w-full sm:h-vw-8 h-vw-16 bg-red-600 absolute bottom-full"></div>
              </div>
            )}
          </label>
        </div>
        <div className="flex sm:flex-row flex-col sm:gap-vw-2 gap-vw-4 w-full sm:mt-vw-2 mt-vw-4">
          <label className="sm:w-1/2">
            <div className="relative">
              <input
                name='inquiry-budget'
                type="number"
                value={inquiryFormData['inquiry-budget']}
                onChange={handleInquiryChange}
                className={`form-text-input w-full ${inquiryFormData['inquiry-budget'] ? 'sm:pt-vw-3.5 pt-vw-10' : ''}`}
              />
              <InputPlaceholder placeholder="Budget" value={inquiryFormData['inquiry-budget']} />
            </div>
          </label>
          <SelectInputNoContext
              options={selectOptions}
              name="inquiry-apartmentType"
              placeholder="Apartment Type"
              className="sm:w-1/2 z-10"
              value={inquiryFormData['inquiry-apartmentType']}
              onChange={(value) => handleInquiryChange({ target: { name: 'inquiry-apartmentType', value } })}
          />
        </div>
        <div className="flex justify-start sm:mt-vw-8 mt-vw-20">
          <CheckboxNoContext
            name="inquiry-policy"
            rules={{ required: true }}
            label={<PolicyText url={siteSetting.privacyPolicyPage.slug}/>}
            checked={inquiryFormData['inquiry-policy']}
            onChange={(checked) => handleInquiryCheckboxChange({ target: { name: 'inquiry-policy', checked } })}
            error = {!!formErrors['inquiry-policy']}
          />
        </div>

        <FormErrors errors={formErrors} />
        <button
            key="inquiry-form-submit"
            type="submit"
            className="big-ass-button w-full text-center outline outline-6 -outline-offset-6 outline-primary relative !px-0 mt-vw-5"
            style={{ pointerEvents: isSubmitting ? 'none' : '' }}
            onClick={ submitInquiry }
          >
            Calculate

            {isSubmitting && (
              <svg className="w-full absolute top-0 left-0 hidden sm:block" viewBox="0 0 575 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="2" y="2" width="571" height="56" rx="19.75" 
                  stroke="white"
                  strokeWidth={6}
                  strokeDasharray={620}
                >
                  <animate attributeName="stroke-dashoffset" values="2037;0;2037" dur="10s" repeatCount="indefinite" />
                </rect>
              </svg>
            )}
          </button>
      </div>
      {successPopupShown && (
        <SuccessPopup onClose={() => setSuccessPopupShown(false)} />
      )}
    </section>
  );
}
